import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/code/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Columns = makeShortcode("Columns");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Columns left="Thielemann" mdxType="Columns">
      <h5>{`Unsere Stärke ist die Integration von IT-Systemen mit technischen Anlagen und Maschinen. Wir verstehen sowohl die Anlagen als auch die IT-Systeme unserer Kunden. Und wir lassen beide effektiv miteinander kommunizieren!`}</h5>
      <p>{`Mit unseren Lösungen sammeln Sie Daten aus verschiedenen Quellen und verteilen sie sicher und fehlerfrei weiter an definierte Zielsysteme.`}</p>
      <h2>{`Wir bieten:`}</h2>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolio/systemintegration/maschinen/"
          }}>{`Willkommen bei den Maschinen-Flüsterern!`}</a>{` Prozessoptimierte Maschinenanbindung`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolio/systemintegration/datenbanken/"
          }}>{`Wir erstellen den Safe für Ihr Daten-Vermögen!`}</a>{`
Pflege von Datenbanken und datenzentrierten Prozessen`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolio/systemintegration/schnittstellen/"
          }}>{`Wir sorgen dafür, dass sich Ihre Daten verstehen!`}</a>{`
Erstellung von Schnittstellen zwischen IT-Systemen`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolio/systemintegration/produktionssysteme/"
          }}>{`Produktion und Sicherheit Ihrer Systeme im Fokus!`}</a>{`
Aufbau mehrschichtiger und verteilter Architekturen`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolio/systemintegration/ecm/"
          }}>{`Die Datenbasis Ihres Unternehmens managen wir!`}</a>{`
Management aller Unternehmensdaten`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolio/systemintegration/big-data/"
          }}>{`Selbst größte Datenmengen kann man lesen lernen!`}</a>{`
Systematische Analyse Ihrer Datenbestände (Big Data)`}</li>
      </ul>
    </Columns>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      